import React from "react";
import SidebarLayout from "./layouts/SidebarLayout";
import AdminDashboard from "./AdminComponents/AdminDashboard";
import AddChapter from "./AdminComponents/AddChapter";
import AddTopics from "./AdminComponents/AddTopics";
import AddSubTopic from "./AdminComponents/AddSubTopic";
import AddNewContent1 from "./AdminComponents/AddNewContent1";
import AddPicture from "./AdminComponents/AddPicture";
const Adminroutes = [
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "/admin/dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "/admin/addpicture",
        element: <AddPicture />,
      },
      {
        path: "/course/:courseid",
        element: <AddChapter />,
      },
      {
        path: "/course/:courseid/topics/:chapterid",
        element: <AddTopics />,
      },
      {
        path: "/course/:courseid/topics/:chapterid/subtopic/:topicid",
        element: <AddSubTopic />,
      },
      {
        path: "/addnewcontent",
        element: <AddNewContent1 />,
      },
      {
        path: "*",
        element: <h1>not found404</h1>,
      },
    ],
  },
];

export default Adminroutes;
