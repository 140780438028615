import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router";
const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate=useNavigate()
  const [user, setUser] = useState(null);
  const login = (role) => {
    if (role === "admin") {
      sessionStorage.setItem("admin", true);
      setUser({ role });
      return true;
    } 
    return false;
  };
  const logout = () => {

    sessionStorage.clear()
    setUser(null);
    navigate('/')
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const withRoleProtection = (allowedrole, WrappedComponent) => {
  return (props) => {
    const { user } = useAuth();
    if (user && allowedrole.includes(user.role)) {
      return <WrappedComponent {...props} />;
    } else {
      return <h1>you do not have permisson</h1>;
    }
  };
};
