import { Button } from "@mui/material";
import Login from "./Login";
import logo from "./logo.svg";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, withRoleProtection } from "./AuthContext/AuthContext";
// import LandingPage from "./LandingPage/LandingPage";
import AdminMain from "./AdminDashboard/AdminMain";
// import PosMain from "./PoshDashboard/PosMain";
// import KitchenMain from "./KitchenDashboard/KitchenMain";

function App() {
  // const { user_teacher } = AuthUser();
  const { user } = useAuth();
  // console.log(user);
  const admin = sessionStorage.getItem("admin");
  
  if (admin) {
    return <AdminMain />;
  }else {
    return (
      <Login />
    );
  }
}

export default App;
