import { Add, Clear, Search } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function AddPicture() {
  const [productimages, setproductiamges] = useState([]);
  const [previewproductimg, setpreviewproductimg] = useState([]);
  const [Dialogopen, setDialogOpen] = useState(false);

  const productimg = (e) => {
    const files = Array.from(e.target.files);
    setproductiamges((prev) => prev.concat(files));
    const prevurl = files.map((file) => URL.createObjectURL(file));
    setpreviewproductimg((prev) => prev.concat(prevurl));
  };
  const removeproductimg = (index) => {
    const newimg = productimages.filter((_, i) => i !== index);
    const newpreview = previewproductimg.filter((_, i) => i !== index);
    URL.revokeObjectURL(previewproductimg[index]);
    setproductiamges(newimg);
    setpreviewproductimg(newpreview);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const addimages = () => {
    const token = sessionStorage.getItem("Token");
    let data = new FormData();
    productimages.forEach((img) => {
      data.append("images[]", img);
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgweb/api/v1/admin/images`,
      headers: {
        Authorization: `Bearer ${token} `,
        "Content-type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        handleDialogClose();
      })
      .catch((error) => {
        handleDialogClose();

        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };

  const [images, setimages] = useState([]);
  const getimages = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/cgweb/api/v1/admin/images",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setimages(response.data);
      })
      .catch((error) => {
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  useEffect(() => {
    getimages();
  }, []);
  const [inputvalue, setInputvalue] = useState("");
  const inputHandler = (e) => {
    setInputvalue(e.target.value);
  };
  const filterItems = images.filter((item) =>
    item.image.toUpperCase().includes(inputvalue.toUpperCase())
  );
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div style={{ padding: "10px" }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <TextField
        className="target-7"
        variant="outlined"
        sx={{
          backgroundColor: "alternate.dark",
          position: "sticky",
          borderRadius: 30,
          zIndex: 5,
          top: 67,
          [`& fieldset`]: {
            borderRadius: 30,
          },
        }}
        fullWidth
        value={inputvalue}
        onChange={inputHandler}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {inputvalue && (
                <Clear
                  sx={{ cursor: "pointer" }}
                  onClick={() => setInputvalue("")}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2} sx={{ pr: 2, pt: 1, mb: 7 }}>
        {filterItems.map((img, i) => (
          <>
            <Grid item xs={6} lg={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140, objectFit: "contain" }}
                  image={img.link}
                  title="green iguana"
                />
                <CardContent>
                  <Typography variant="h6" sx={{mb:1}}>
                    {img.image}
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    fontWeight={600}
                    variant="body1"
                    component="div"
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      height: smallscreen ? 70 : 50,
                      width: smallscreen ? "9rem" : "20rem",
                      wordWrap: "break-word",
                      WebkitLineClamp: 2,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {img.link}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Share</Button>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          className="target-1"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleDialogOpen}
        >
          <Add /> Add New Images{" "}
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">Add img </Typography>
          <Typography fontWeight={600}>Images</Typography>
          <input type="file" multiple onChange={productimg} accept="image/*" />
          <Grid container spacing={2} sx={{ mt: 4 }}>
            {previewproductimg.map((photo, index) => (
              <Grid xs={4} lg={6} gap={2} padding={2}>
                <img src={photo} width={300} height={300} alt="" />
                <IconButton
                  color="error"
                  onClick={() => removeproductimg(index)}
                >
                  <Clear />
                </IconButton>
              </Grid>
            ))}
          </Grid>{" "}
        </DialogTitle>

        <DialogActions>
          <Button onClick={addimages}>Add Images</Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddPicture;
