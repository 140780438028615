import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add,
  DeleteTwoTone,
  EditTwoTone,
} from "@mui/icons-material";
import axios from "axios";
import {  useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
function AddSubTopic() {
  const { courseid, chapterid, topicid } = useParams();
  const [subtopics, setsubTopics] = useState([]);
  const getsubTopics = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgweb/api/v1/admin/getTopics/${courseid}/${chapterid}/${topicid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setsubTopics(response.data.subtopics);
      })
      .catch((error) => {
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  useEffect(() => {
    getsubTopics();
  }, []);
  const navigate = useNavigate();
  const handleClick = () => {
    const data = {
      course_id: courseid,
      chapter_id: chapterid,
      topic_id: topicid,
    };
    navigate("/addnewcontent", { state: data });
  };
  const handleClick1 = (id) => {
    const data = {
      course_id: courseid,
      chapter_id: chapterid,
      topic_id: topicid,
      subtopicid: id,
    };
    navigate("/addnewcontent", { state: data });
  };

  const goback1 = () => {
    navigate("/admin/dashboard");
  };
  const goback2 = () => {
    navigate(-2);
  };
  const goback3 = () => {
    navigate(-1);
  };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteDialog, setdeleteDialog] = React.useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [deletename, setDeletename] = useState("");
  const deleteDialogOpen = (id, name) => {
    setdeleteDialog(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const deleteDialogClose = () => {
    setdeleteDialog(false);
    setDeleteid("");
    setDeletename("");
  };
  const deletesubTopic = (id) => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgweb/api/v1/admin/deleteSubtopic/${deleteid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        getsubTopics();
        deleteDialogClose();
        toast.success(response.data, {
          position: "bottom-right",
          duration: 3000,
        });
      })
      .catch((error) => {
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  return (
    <div>
       <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Dialog
        open={deleteDialog}
        onClose={deleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete??</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            are you sure delete {deletename} &nbsp;Subtopic ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteDialogClose}>Cancel</Button>
          <Button color="error" onClick={deletesubTopic} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ marginLeft: 20, marginTop: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            color="text.primary"
            onClick={goback1}
            sx={{ cursor: "pointer" }}
          >
            Home
          </Typography>
          <Typography
            color="text.primary"
            onClick={goback2}
            sx={{ cursor: "pointer" }}
          >
            Course name
          </Typography>
          <Typography
            color="text.primary"
            onClick={goback3}
            sx={{ cursor: "pointer" }}
          >
            Chapters Name
          </Typography>
          <Typography color="text.primary" variant="h5">
            Subtopic names
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid container spacing={2} sx={{ pr: 2, mb: 7 }}>
        {subtopics.map((item, index) => (
          <Grid key={index} item xs={6} md={4} lg={3} sx={{ p: 1 }}>
            <Card
              sx={{
                width: smallscreen ? 190 : 260,
                height: 250,
                borderRadius: 2,
                backgroundSize: "cover",
                transform: "scale(0.9)",
                transition: " all ease-in 0.2s",
                "&:hover": {
                  transform: "scale(1)",
                  filter: `drop-shadow(8px 8px 10px black)`,
                },
              }}
              key={index}
            >
              <CardContent sx={{ mt: 1.5 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {item.name?.toUpperCase()}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {item.desc}
                  </Typography>
                </div>
                <div style={{ position: "fixed", bottom: 10, right: 100 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <IconButton
                      onClick={() => handleClick1(item.id)}
                      color="primary"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.1)",
                          transition: "all 0.5s",
                        },
                      }}
                    >
                      <Tooltip title="Edit">
                        <EditTwoTone />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      onClick={() => deleteDialogOpen(item.id, item.name)}
                      color="error"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.1)",
                          transition: "all 0.5s",
                        },
                      }}
                    >
                      <Tooltip title="Delete">
                        <DeleteTwoTone />
                      </Tooltip>
                    </IconButton>
                  </div>
                </div>
                    </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          className="target-1"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleClick}
        >
          <Add /> Add New Subtopic with content
        </Button>
      </div>
    </div>
  );
}

export default AddSubTopic;
