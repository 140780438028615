import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowForward,
  DeleteTwoTone,
  EditTwoTone,
} from "@mui/icons-material";
import axios from "axios";
import { Link, } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Tabs, Tab } from "@mui/material";

function AdminDashboard() {

  const [course, SetCourse] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);

  const groupedCourses = course.reduce((acc, course) => {
    if (!acc[course.type]) {
      acc[course.type] = [];
    }
    acc[course.type].push(course);
    return acc;
  }, {});

  const getTabLabel = (type) => {
    switch (type) {
      case 1:
        return "6 to 8 class";
      case 2:
        return "9 to 12 class";
      case 3:
        return "graduation";
      case 4:
        return "post graduation";
      default:
        return `Type ${type}`;
    }
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getCourse = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/cgweb/api/v1/admin/CourseListAll",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        SetCourse(response.data);
      })
      .catch((error) => {
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  useEffect(() => {
    getCourse();
  }, []);
  const [Dialogopen, setDialogOpen] = useState(false);
  const [name, setName] = useState("");

  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setName("");
    setEditMode(false);
  };

  const AddCourse = () => {
    if (editMode) {
      const token = sessionStorage.getItem("Token");
      let data = {
        name: name
      };

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/cgweb/api/v1/admin/updateCourse/${editIndex}`,
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
          getCourse();
          handleDialogClose();
        })
        .catch((error) => {
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    } else {
      let data = {
        name: name,
        type:selectedTab
      };
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://simplesoft.co.in/cgweb/api/v1/admin/addCourse",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          getCourse();
          handleDialogClose();
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    }
  };

  const handleEditItem = (index, id) => {
    setEditMode(true);
    setEditIndex(id);
    setName(course[index].name);
    handleDialogOpen(true);
  };
  const [deleteDialog, setdeleteDialog] = React.useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [deletename, setDeletename] = useState("");
  const deleteDialogOpen = (id, name) => {
    setdeleteDialog(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const deleteDialogClose = () => {
    setdeleteDialog(false);
    setDeleteid("");
    setDeletename("");
  };

  const deleteCourse = (id) => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgweb/api/v1/admin/deleteCourse/${deleteid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        getCourse();
        deleteDialogClose();
        toast.success(response.data, {
          position: "bottom-right",
          duration: 3000,
        });
      })
      .catch((error) => {
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
         className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Dialog
        open={deleteDialog}
        onClose={deleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete??</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            are you sure delete {deletename} &nbsp;Course?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteDialogClose}>Cancel</Button>
          <Button color="error" onClick={deleteCourse} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    
      <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="course types"
        >
          {Object.keys(groupedCourses).map((type) => (
            <Tab
              key={type}
              label={getTabLabel(parseInt(type, 10))}
              value={parseInt(type, 10)}
            />
          ))}
        </Tabs>

        {Object.keys(groupedCourses).map((type) => (
          <div key={type} hidden={parseInt(type, 10) !== selectedTab}>
            <Grid container spacing={2} sx={{ pr: 2, pt: 1, mb: 7 }}>
              {groupedCourses[type].map((item, index) => (
                <Grid key={item.id} item xs={6} md={4} lg={3} sx={{ p: 1 }}>
                  <Card
                    sx={{
                      // width: 260,
                      // height: 180,
                      borderRadius: 2,
                      backgroundSize: "cover",
                      transform: "scale(0.9)",
                      transition: "all ease-in 0.2s",
                      "&:hover": {
                        transform: "scale(1)",
                        filter: `drop-shadow(8px 8px 10px black)`,
                      },
                    }}
                  >
                    <CardContent sx={{ mt: 1.5 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            sx={{ whiteSpace: "pre-wrap" }}
                          >
                            {item.name?.toUpperCase()}
                          </Typography>
                          <br />
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{ whiteSpace: "pre-wrap" }}
                          >
                            Chapter : {item.count}
                          </Typography>
                        </div>
                        <IconButton
                          component={Link}
                          to={`/course/${item.id}`}
                          color="primary"
                          size="small"
                          sx={{
                            "&:hover": {
                              transform: "scale(1.1)",
                              transition: "all 0.5s",
                            },
                          }}
                        >
                          <Tooltip title={`Go to ${item.name}`}>
                            <ArrowForward />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </CardContent>
                    <CardActions>
                      <div>
                        <IconButton
                          onClick={() => handleEditItem(index, item.id)}
                          color="primary"
                          size="small"
                          sx={{
                            "&:hover": {
                              transform: "scale(1.1)",
                              transition: "all 0.5s",
                            },
                          }}
                        >
                          <Tooltip title="Edit">
                            <EditTwoTone />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() => deleteDialogOpen(item.id, item.name)}
                          color="error"
                          size="small"
                          sx={{
                            "&:hover": {
                              transform: "scale(1.1)",
                              transition: "all 0.5s",
                            },
                          }}
                        >
                          <Tooltip title="Delete">
                            <DeleteTwoTone />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          className="target-1"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleDialogOpen}
        >
          <Add /> Add New Course
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">Add Course Name</Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            placeholder="Course Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
           
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          {/* <Button color="error"> */}
          <Button onClick={AddCourse}>{editMode ? "Update" : "Add"}</Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminDashboard;
