import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import MDEditor from "@uiw/react-md-editor";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { Clear, Search } from "@mui/icons-material";
function AddNewContent1() {
  const location = useLocation();
  const { course_id, chapter_id, topic_id, subtopicid } = location.state || {};
  const [value, setValue] = React.useState("**Hello world!!!**");
  const [name, setName] = useState("");
  const getsubTopics = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgweb/api/v1/admin/getTopics/${course_id}/${chapter_id}/${topic_id}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setContent(response.data.subtopics);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getsubTopics();
  }, []);
  const [content, setContent] = useState([]);

  const filtervalue = content.filter((value) => value.id === subtopicid);

  const navigate = useNavigate();

  const goback1 = () => {
    navigate("/admin/dashboard");
  };
  const goback2 = () => {
    navigate(-3);
  };
  const goback3 = () => {
    navigate(-2);
  };
  const goback4 = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (filtervalue.length > 0) {
      setName(filtervalue[0]?.name);
      setValue(filtervalue[0]?.content);
    }
  }, [content]);

  const AddsubTopic = () => {
    if (subtopicid) {
      const token = sessionStorage.getItem("Token");
      let data = {
        name: name,
        course_id: course_id,
        chapter_id: chapter_id,
        topic_id: topic_id,
        content: value,
      };
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/cgweb/api/v1/admin/updateSubtopic/${subtopicid}`,
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          goback4();
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    } else {
      let data = {
        name: name,
        course_id: course_id,
        chapter_id: chapter_id,
        topic_id: topic_id,
        content: value,
      };
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://simplesoft.co.in/cgweb/api/v1/admin/addSubtopic",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
          goback4();
        })
        .catch((error) => {
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    }
  };
  const [images, setimages] = useState([]);
  const getimages = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/cgweb/api/v1/admin/images",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setimages(response.data);
      })
      .catch((error) => {
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  useEffect(() => {
    getimages();
  }, []);
  const [inputvalue, setInputvalue] = useState("");
  const inputHandler = (e) => {
    setInputvalue(e.target.value);
  };
  const filterItems = images.filter((item) =>
    item.image.toUpperCase().includes(inputvalue.toUpperCase())
  );
  const [ancel, setancel] = useState(null);
  const [copied, setcopied] = useState("");
  const handleopenmenu = (e) => {
    setancel(e.currentTarget);
  };
  const handleclosemenu = (e) => {
    setancel(null);
  };

  const handlecopy = (name) => {
    setcopied(name);
    setTimeout(() => {
      setcopied("");
    }, 2000);
    handleclosemenu();
  };
console.log(filterItems)
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            color="text.primary"
            onClick={goback1}
            sx={{ cursor: "pointer" }}
          >
            Home
          </Typography>
          <Typography
            color="text.primary"
            onClick={goback2}
            sx={{ cursor: "pointer" }}
          >
            Course name
          </Typography>
          <Typography
            color="text.primary"
            onClick={goback3}
            sx={{ cursor: "pointer" }}
          >
            Chapters Name
          </Typography>
          <Typography
            color="text.primary"
            onClick={goback4}
            sx={{ cursor: "pointer" }}
          >
            Subtopic names
          </Typography>
          <Typography color="text.primary" variant="h5">
            {filtervalue[0]?.name || "new content"}
          </Typography>
        </Breadcrumbs>
      </div>
      <TextField
        sx={{ mb: 2, mt: 2 }}
        fullWidth
        placeholder="Subtopic name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button variant="contained" onClick={handleopenmenu}>
        select image
      </Button>
      <Menu
        anchorEl={ancel}
        open={Boolean(ancel)}
        onClose={handleclosemenu}
        PaperProps={{
          style: { maxHeight: 350, width: "300px" },
        }}
      >
        <Box px={2} pt={1}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="search.."
            value={inputvalue}
            onChange={inputHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {inputvalue && (
                    <Clear
                      sx={{ cursor: "pointer" }}
                      onClick={() => setInputvalue("")}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {filterItems.map((option, i) => (
          <CopyToClipboard
            key={i}
            text={`![images](${option.link})`}
            onCopy={() => handlecopy(`![images](${option.link})`)}
          >
            <MenuItem button>
              <ListItemAvatar>
                <Avatar src={option.link} alt="img" />
              </ListItemAvatar>
              <ListItemText primary={option.image_name} />
            </MenuItem>
          </CopyToClipboard>
        ))}
        {filterItems.length===0&&(<Typography variant="body2" align="center" sx={{p:2}}>No result Found</Typography>)}
      </Menu>
      <MDEditor value={value} onChange={setValue} />
      <MDEditor.Markdown source={value} style={{ whiteSpace: "pre-wrap" }} />
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button variant="contained" onClick={AddsubTopic}>
          {subtopicid
            ? "Update Content with subtopic"
            : "Add Content with subtopic"}
        </Button>
      </div>
    </div>
  );
}

export default AddNewContent1;
